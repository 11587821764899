@use 'sass:math';
@import 'styles/theme';

.EditoCard {
  position: relative;
  user-select: none;

  .image {
    height: auto;
  }

  .title {
    margin-top: theme($spacings, 10);
  }

  .pin {
    z-index: 2;
    position: absolute;
    top: theme($spacings, 10);
    right: theme($spacings, 10);
  }

  .ratio {
    @include applyPercentRatios(math.div(312, 416));
  }
}
