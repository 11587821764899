@import 'styles/theme';

.ErrorLinks {
  text-align: center;
  margin-top: theme($spacings, 50);
  margin-bottom: theme($spacings, 50);

  @include from('md') {
    margin-top: theme($spacings, 60);
    margin-bottom: theme($spacings, 60);
  }

  .title,
  .links {
    grid-column: 5 / -5;
  }

  .links {
    margin-top: theme($spacings, 20);
    margin-bottom: theme($spacings, 20);
  }

  .link {
    margin-left: theme($spacings, 10);
    margin-right: theme($spacings, 10);

    @include from('md') {
      margin-left: theme($spacings, 20);
      margin-right: theme($spacings, 20);
    }
  }
}
